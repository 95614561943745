/* Essential map container styles */
#map {
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* Ensure the entire app takes up full space */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

/* Leaflet specific styles */
.leaflet-container {
  width: 100%;
  height: 100%;
}

/* Fix for Leaflet controls z-index issues */
.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  z-index: 1000 !important;
}

/* Ensure proper sizing for popups */
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
  max-height: 300px;
  overflow-y: auto;
}

/* Custom map controls */
.map-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
}

.map-legend {
  position: absolute;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  max-width: 200px;
}

.volume-legend {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: #FAF0DC;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-height: 80vh;
  z-index: 1000;
}

.zoom-info {
  padding: 6px 8px;
  font: 16px/18px Arial, Helvetica, sans-serif;
  text-align: center;
  background: #FAF0DC;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
}

@media (min-width: 768px) {
  .zoom-info {
    font: 18px/20px Arial, Helvetica, sans-serif;
    transform: translate(-50vw, -30px);
    width: 40vw;
  }
}

/* Fix for Leaflet marker icons */
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block !important;
}

/* Custom marker styles */
.home-work-marker, .home-marker, .poi-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
}

.home-work-marker {
  background-color: #584ac5;
}

.home-marker {
  background-color: #0C423E;
}

.poi-marker {
  background-color: #ff6864;
}

.cnt-marker-green, .cnt-marker-yellow, .cnt-marker-red {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
}

.cnt-marker-red {
  background-color: #FF5722;
}

.cnt-marker-yellow {
  background-color: #FFC107;
}

.cnt-marker-green {
  background-color: #4CAF50;
}

.marker-label {
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: white;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.marker-label-large {
  font-size: 24px;
}

/* Demographic area styles */
.demographic-area {
  stroke: #4a76a8;
  stroke-width: 3;
  stroke-opacity: 0.8;
  fill: #4a76a8;
  fill-opacity: 0.3;
}

.demographic-area:hover {
  stroke: #5e8cc0;
  stroke-width: 5;
  fill-opacity: 0.5;
}

/* Spinner for loading */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hidden {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .map-controls, .map-legend {
    max-width: 150px;
    font-size: 0.9em;
  }
} 
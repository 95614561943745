/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden !important;
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  line-height: 1.6;
  position: relative;
}

#root {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

a {
  color: #322862;
  text-decoration: none;
}

a:hover {
  color: #ff6864;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  line-height: 1.2;
  word-wrap: break-word;
}

p {
  margin-bottom: 1rem;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

button {
  cursor: pointer;
}

/* Fix for common overflow causes */
table {
  max-width: 100%;
  display: block;
  overflow-x: auto;
}

pre, code {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Fix for grid and flex layouts */
.row, .grid, [class*="grid-"], [class*="row-"], [class*="col-"] {
  width: auto;
  max-width: 100%;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-3 { margin-top: 1.5rem; }
.mt-4 { margin-top: 2rem; }
.mt-5 { margin-top: 3rem; }

.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-3 { margin-bottom: 1.5rem; }
.mb-4 { margin-bottom: 2rem; }
.mb-5 { margin-bottom: 3rem; }

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}